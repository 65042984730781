import { Box, Typography } from "@mui/material"
import React from "react"

const NewsletterEmbed = ({ theme }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#EEF8EF",
        p: "10px",
      }}
      id="newsletter"
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: "28px",
          fontFamily: `"Poppins", "sans-serif" `,
          fontWeight: 500,
          marginTop: "80px",
          marginBottom: "-40px",
          textAlign: "center",
          zIndex: 99,
          [theme.breakpoints.down("sm")]: {
            marginTop: "60px",
            fontSize: "22px",
          },
        }}
      >
        Subscribe to our weekly email newsletter
      </Typography>
      <iframe
        className="learn-iframe"
        src="https://99rises.substack.com/embed"
        height="320"
        frameborder="0"
        scrolling="no"
        style={{ background: "#EEF8EF" }}
      ></iframe>
    </Box>
  )
}

export default NewsletterEmbed
